import React from 'react';
import { Helmet } from 'react-helmet-async';

function Contact() {
  return (
    <div className={'z-0'}>
      <Helmet>
        <title>CONTACT | HWAGA;AK</title>
      </Helmet>
      <main className="flex flex-col bg-[#0B3F61] lg:flex-row items-center justify-center min-h-screen mt-14">
        <div className="flex flex-col sm:items-start md:items-start justify-center lg:ml-52 lg:mb-32 w-full lg:w-1/2 p-5 mt-20 fade-in text-white">
          <img
            src="/assets/img/logoWhite.png"
            alt="화각 로고"
            className="w-68 mb-5"
          />
          <div className="text-3xl font-medium text-left pl-3 text-gray-200">
            Republic of Korea
          </div>
          <div className="text-xl font-medium mt-2 text-left pl-4">
            HWAGA;AK studio
          </div>
          <p className="text-md mt-4 font-sm pl-4">Tel. 010-4035-0366</p>
          <p className="text-md font-sm pl-4 mb-1">hwaga.ak@gmail.com</p>
          <div className="text-sm text-left mt-5 pl-4">©2024</div>
        </div>
        <div className="flex flex-col ism:items-start md:items-start lg:items-end justify-center text-left lg:text-right mt-10 lg:mt-10 lg:mr-64 w-full lg:w-1/2 p-4 fade-in"></div>
      </main>
    </div>
  );
}

export default Contact;
