// src/components/MouseEffect.js
import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import './MouseEffect.css';

const MouseEffect = () => {
  const [xy, setXY] = useState({ x: 0, y: 0 });
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const xyHandler = (e) => {
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    setXY({ x: mouseX, y: mouseY });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isDesktop) return;

    const cursor = document.querySelector('.mouse__cursor');
    const cursor2 = document.querySelector('.mouse__cursor2');

    const updateCursor = (e) => {
      gsap.to(cursor, { duration: 0.3, left: e.pageX - 5, top: e.pageY - 5 });
      gsap.to(cursor2, {
        duration: 0.8,
        left: e.pageX - 15,
        top: e.pageY - 15,
      });
    };

    window.addEventListener('mousemove', updateCursor);

    document.querySelectorAll('.mouse__text span').forEach((el) => {
      el.addEventListener('mouseenter', () => {
        cursor.classList.add('active');
        cursor2.classList.add('active');
      });
      el.addEventListener('mouseleave', () => {
        cursor.classList.remove('active');
        cursor2.classList.remove('active');
      });
    });

    return () => {
      window.removeEventListener('mousemove', updateCursor);
    };
  }, [isDesktop]);

  if (!isDesktop) return null;

  return (
    <div className="mouse__wrap" onMouseMove={xyHandler}>
      <div className="mouse__cursor"></div>
      <div className="mouse__cursor2"></div>
      <div className="mouse__text">
        {/*<p>*/}
        {/*  Fix you course on a <span>star</span> and you'll{' '}*/}
        {/*  <span>navigate any storm</span>*/}
        {/*</p>*/}
        {/*<p>*/}
        {/*  당신의 <span>별</span>을 보고 방향을 정해라. 그러면{' '}*/}
        {/*  <span>폭풍 속에서도 항해</span>를 계속 해 나갈 수 있을것이다*/}
        {/*</p>*/}
      </div>
    </div>
  );
};

export default MouseEffect;
