import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Work from './pages/Work';
import About from './pages/About';
import Contact from './pages/Contact';
import WorkCf from './pages/WorkCf';
import WorkContents from './pages/WorkContents';
import WorkMv from './pages/WorkMv';
import Layout from './components/common/Layout';
import MouseEffect from './components/common/MouseEffect';
import ScrollToTop from './components/common/ScrollToTop';
import '../src/fonts/font.css';

function App() {
  return (
    <div>
      <MouseEffect />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/works" element={<Work />} />
            <Route path="/works/cf" element={<WorkCf />} />
            <Route path="/works/mv" element={<WorkMv />} />
            <Route path="/works/contents" element={<WorkContents />} />
            <Route path="/contact" element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
