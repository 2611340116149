export const contentsItems = [
  {
    src: '/assets/img/contents/celeb_an_berryo.jpg',
    title: '[셀럽로그인] 안민영_베리오',
    videoUrl: 'https://player.vimeo.com/video/980648572?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_an_uneeda.jpg',
    title: '[셀럽로그인] 안민영_유니다',
    videoUrl: 'https://player.vimeo.com/video/980647115?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_an_herb.jpg',
    title: '[셀럽로그인] 안민영_바인허브',
    videoUrl: 'https://player.vimeo.com/video/980645373?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_ham_rap.jpg',
    title: '[셀럽로그인] 함은정_랩도리',
    videoUrl: 'https://player.vimeo.com/video/980644327?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_ham_dday.jpg',
    title: '[셀럽로그인] 함은정_디데이헬스',
    videoUrl: 'https://player.vimeo.com/video/980642466?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_ham_dr.jpg',
    title: '[셀럽로그인] 함은정_닥터로하스',
    videoUrl: 'https://player.vimeo.com/video/980641649?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_yu_mydday.jpg',
    title: '[셀럽로그인] 유시은_마이디데이',
    videoUrl: 'https://player.vimeo.com/video/980639986?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_yu_rucipello.jpg',
    title: '[셀럽로그인] 유시은_루치펠로',
    videoUrl: 'https://player.vimeo.com/video/980639167?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_yu_nain.jpg',
    title: '[셀럽로그인] 유시은_나인밀라',
    videoUrl: 'https://player.vimeo.com/video/980638234?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_yu_tersh.jpg',
    title: '[셀럽로그인] 유시은_테르스',
    videoUrl: 'https://player.vimeo.com/video/980637519?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_yu_dewbell.jpg',
    title: '[셀럽로그인] 유시은_듀벨',
    videoUrl: 'https://player.vimeo.com/video/980636957?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_gong_oo.jpg',
    title: '[셀럽로그인] 공민지_OOOO',
    videoUrl: 'https://player.vimeo.com/video/980635044?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_gong_cushion.jpg',
    title: '[셀럽로그인] 공민지_앙쥬블랙쿠션',
    videoUrl: 'https://player.vimeo.com/video/980633178?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_gong_saffeat.jpg',
    title: '[셀럽로그인] 공민지_사피트',
    videoUrl: 'https://player.vimeo.com/video/980631226?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_gong_persona.jpg',
    title: '[셀럽로그인] 공민지_르페르소나',
    videoUrl: 'https://player.vimeo.com/video/980629358?autoplay=1',
  },
];
