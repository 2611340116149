import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { CSSTransition } from 'react-transition-group';
import React from 'react';

function Modal({
  modalIsOpen,
  closeModal,
  currentVideoUrl,
  handlePrev,
  handleNext,
  currentTitle,
}) {
  return (
    <CSSTransition
      in={modalIsOpen}
      timeout={300}
      classNames="modal"
      unmountOnExit
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
        onClick={closeModal} // 배경 클릭 시 모달 닫힘
      >
        <div
          className="relative w-full max-w-6xl bg-white xs: pt-20 xs: h-full md:h-auto md:p-12 md:rounded"
          onClick={(e) => e.stopPropagation()} // 모달 내부 클릭 시 이벤트 전파 중지
        >
          <button
            onClick={closeModal}
            className="absolute top-0 right-0 mt-2 mr-4 text-black text-2xl xs: mb-12"
          >
            &times;
          </button>
          <div className="relative aspect-w-16 aspect-h-9">
            <iframe
              className="w-full h-full"
              src={currentVideoUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
          <div className="absolute top-1/2 left-0 transform -translate-y-1/2 sm: hidden md:block">
            <button
              onClick={handlePrev}
              className="text-black text-2xl md:p-3 sm:p-0"
            >
              <IoIosArrowBack />
            </button>
          </div>
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 sm: hidden md:block">
            <button
              onClick={handleNext}
              className="text-black text-2xl md:p-3 sm:p-0"
            >
              <IoIosArrowForward />
            </button>
          </div>
          <div className="mt-8 text-center">
            <h2 className="text-xl font-semibold">{currentTitle}</h2>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Modal;
