const Footer = () => {
  return (
    <footer className="bg-[#0B3F61] text-white py-3">
      <div className="container mx-auto flex flex-col md:flex-row justify-center items-center montserrat">
        <div className=" ">COPYRIGHT © 2024 - HWAGA;AK </div>
      </div>
    </footer>
  );
};

export default Footer;
