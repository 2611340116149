export const worksItems = [
  {
    src: '/assets/img/mv/cha.jpg',
    title: '차세대_사막에서 온 사람',
    videoUrl: 'https://player.vimeo.com/video/981894170?autoplay=1',
  },
  {
    src: '/assets/img/cf/peachHolic.jpg',
    title: '피치홀릭',
    videoUrl: 'https://player.vimeo.com/video/980548886?autoplay=1',
  },
  {
    src: '/assets/img/cf/fireEp1.jpg',
    title: '경기도소방재난본부 공익광고_화재경보음 ep.1',
    videoUrl: 'https://player.vimeo.com/video/979689048?autoplay=1',
  },
  {
    src: '/assets/img/cf/gyeonggido.jpg',
    title: '경기도공익활동센터 공익광고',
    videoUrl: 'https://player.vimeo.com/video/979683037?autoplay=1',
  },
  {
    src: '/assets/img/cf/fire2.jpg',
    title: '경기도소방본부 공익광고_화재경보음 ep.2',
    videoUrl: 'https://player.vimeo.com/video/980557151?autoplay=1',
  },
  {
    src: '/assets/img/cf/hillState.jpg',
    title: '힐스테이트',
    videoUrl: 'https://player.vimeo.com/video/980547575?autoplay=1',
  },
  {
    src: '/assets/img/cf/media.jpg',
    title: '시청자미디어재단',
    videoUrl: 'https://player.vimeo.com/video/980545618?autoplay=1',
  },
  {
    src: '/assets/img/cf/summit.jpg',
    title: '써밋플레이',
    videoUrl: 'https://player.vimeo.com/video/980542563?autoplay=1',
  },
  {
    src: '/assets/img/cf/myfool2.jpg',
    title: '어학 어플리케이션 마이풀',
    videoUrl: 'https://player.vimeo.com/video/980539451?autoplay=1',
  },
  {
    src: '/assets/img/cf/myfool1.jpg',
    title: '어학 어플리케이션 마이풀',
    videoUrl: 'https://player.vimeo.com/video/980534965?autoplay=1',
  },
  {
    src: '/assets/img/cf/ample.jpg',
    title: 'AMPLE:N',
    videoUrl: 'https://player.vimeo.com/video/979672285?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_an_berryo.jpg',
    title: '[셀럽로그인] 안민영_베리오',
    videoUrl: 'https://player.vimeo.com/video/980648572?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_an_uneeda.jpg',
    title: '[셀럽로그인] 안민영_유니다',
    videoUrl: 'https://player.vimeo.com/video/980647115?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_an_herb.jpg',
    title: '[셀럽로그인] 안민영_바인허브',
    videoUrl: 'https://player.vimeo.com/video/980645373?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_ham_rap.jpg',
    title: '[셀럽로그인] 함은정_랩도리',
    videoUrl: 'https://player.vimeo.com/video/980644327?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_ham_dday.jpg',
    title: '[셀럽로그인] 함은정_디데이헬스',
    videoUrl: 'https://player.vimeo.com/video/980642466?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_ham_dr.jpg',
    title: '[셀럽로그인] 함은정_닥터로하스',
    videoUrl: 'https://player.vimeo.com/video/980641649?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_yu_mydday.jpg',
    title: '[셀럽로그인] 유시은_마이디데이',
    videoUrl: 'https://player.vimeo.com/video/980639986?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_yu_rucipello.jpg',
    title: '[셀럽로그인] 유시은_루치펠로',
    videoUrl: 'https://player.vimeo.com/video/980639167?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_yu_nain.jpg',
    title: '[셀럽로그인] 유시은_나인밀라',
    videoUrl: 'https://player.vimeo.com/video/980638234?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_yu_tersh.jpg',
    title: '[셀럽로그인] 유시은_테르스',
    videoUrl: 'https://player.vimeo.com/video/980637519?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_yu_dewbell.jpg',
    title: '[셀럽로그인] 유시은_듀벨',
    videoUrl: 'https://player.vimeo.com/video/980636957?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_gong_oo.jpg',
    title: '[셀럽로그인] 공민지_OOOO',
    videoUrl: 'https://player.vimeo.com/video/980635044?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_gong_cushion.jpg',
    title: '[셀럽로그인] 공민지_앙쥬블랙쿠션',
    videoUrl: 'https://player.vimeo.com/video/980633178?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_gong_saffeat.jpg',
    title: '[셀럽로그인] 공민지_사피트',
    videoUrl: 'https://player.vimeo.com/video/980631226?autoplay=1',
  },
  {
    src: '/assets/img/contents/celeb_gong_persona.jpg',
    title: '[셀럽로그인] 공민지_르페르소나',
    videoUrl: 'https://player.vimeo.com/video/980629358?autoplay=1',
  },
];
