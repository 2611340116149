import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { RxHamburgerMenu } from 'react-icons/rx';
import { HiBars3 } from 'react-icons/hi2';

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const location = useLocation();

  const showSidebar = () => setSidebar(!sidebar);

  const getLinkClass = (path) => {
    return location.pathname === path
      ? 'text-[#0B3F61] font-bold'
      : 'text-black';
  };

  const isHome = location.pathname === '/';
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile width threshold
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <header className="fixed w-full p-3 flex justify-between items-center z-50">
        {isHome ? (
          <div className="absolute inset-0 md:bg-gray-100 opacity-90 z-0 sm: bg-transparent"></div>
        ) : (
          <div className="absolute inset-0  bg-gray-100 md:bg-gray-100  z-0 "></div>
        )}
        <div className="relative flex justify-between items-center w-full z-10 font-semibold">
          <div className="text-center ml-2">
            <Link to="/" className="text-2xl">
              {!(isHome && isMobile) && (
                <img
                  src="/assets/img/logo1.png"
                  alt="화각 로고"
                  className="w-24 md:w-32 lg:w-28"
                />
              )}
            </Link>
          </div>
          <nav className="hidden md:flex flex-1 justify-end space-x-8 mr-10">
            <Link
              to="/about"
              className={`text-lg ${getLinkClass('/about')} hover:text-gray-800`}
            >
              {' '}
              About{' '}
            </Link>{' '}
            <Link
              to="/works"
              className={`text-lg ${getLinkClass('/works')} hover:text-gray-800`}
            >
              {' '}
              Works{' '}
            </Link>{' '}
            <Link
              to="/contact"
              className={`text-lg ${getLinkClass('/contact')} hover:text-gray-800`}
            >
              {' '}
              Contact{' '}
            </Link>
          </nav>
          <HiBars3
            onClick={showSidebar}
            className="text-3xl cursor-pointer text-[#0B3F61] md:hidden"
          />
        </div>
      </header>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 w-full h-full bg-black transition-opacity duration-300 ${
          sidebar ? 'opacity-50 z-40' : 'opacity-0 z-0 pointer-events-none'
        }`}
        onClick={showSidebar}
      ></div>

      <nav
        className={`fixed top-0 right-0 h-full bg-slate-50 bg-opacity-95 transform ${
          sidebar ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out w-80 md:w-1/3 z-50`}
      >
        <div className="p-4 flex justify-end">
          <FaTimes
            onClick={showSidebar}
            className="text-black text-2xl cursor-pointer"
          />
        </div>
        <ul className="flex flex-col items-center justify-start h-full mt-4">
          <li className="py-4 ">
            <Link
              to="/about"
              className="text-black hover:text-[#0B3F61] text-2xl font-bold "
              onClick={showSidebar}
            >
              ABOUT{' '}
            </Link>
          </li>
          <li className="pt-4 pb-2">
            <Link
              to="/works"
              className="text-black hover:text-[#0B3F61] text-2xl font-bold"
              onClick={showSidebar}
            >
              WORKS
            </Link>
          </li>
          <li className="py-1">
            <Link
              to="/works/cf"
              className="text-gray-800 hover:text-[#0B3F61] text-lg font-medium"
              onClick={showSidebar}
            >
              - CF
            </Link>
          </li>
          <li className="py-1">
            <Link
              to="/works/mv"
              className="text-gray-700 hover:text-[#0B3F61] text-lg font-medium"
              onClick={showSidebar}
            >
              - Mv
            </Link>
          </li>
          <li className="py-1">
            <Link
              to="/works/contents"
              className="text-gray-800 hover:text-[#0B3F61] text-lg font-medium"
              onClick={showSidebar}
            >
              - CONTENTS
            </Link>
          </li>

          <li className="py-5">
            <Link
              to="/contact"
              className="text-black hover:text-[#0B3F61] text-2xl font-bold"
              onClick={showSidebar}
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
