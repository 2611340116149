export const cfItems = [
  {
    src: '/assets/img/cf/peachHolic.jpg',
    title: '피치홀릭',
    videoUrl: 'https://player.vimeo.com/video/980548886?autoplay=1',
  },
  {
    src: '/assets/img/cf/fireEp1.jpg',
    title: '경기도소방재난본부 공익광고_화재경보음 ep.1',
    videoUrl: 'https://player.vimeo.com/video/979689048?autoplay=1',
  },
  {
    src: '/assets/img/cf/gyeonggido.jpg',
    title: '경기도공익활동센터 공익광고',
    videoUrl: 'https://player.vimeo.com/video/979683037?autoplay=1',
  },
  {
    src: '/assets/img/cf/fire2.jpg',
    title: '경기도소방본부 공익광고_화재경보음 ep.2',
    videoUrl: 'https://player.vimeo.com/video/980557151?autoplay=1',
  },
  {
    src: '/assets/img/cf/hillState.jpg',
    title: '힐스테이트',
    videoUrl: 'https://player.vimeo.com/video/980547575?autoplay=1',
  },
  {
    src: '/assets/img/cf/media.jpg',
    title: '시청자미디어재단',
    videoUrl: 'https://player.vimeo.com/video/980545618?autoplay=1',
  },
  {
    src: '/assets/img/cf/summit.jpg',
    title: '써밋플레이',
    videoUrl: 'https://player.vimeo.com/video/980542563?autoplay=1',
  },
  {
    src: '/assets/img/cf/myfool2.jpg',
    title: '어학 어플리케이션 마이풀',
    videoUrl: 'https://player.vimeo.com/video/980539451?autoplay=1',
  },
  {
    src: '/assets/img/cf/myfool1.jpg',
    title: '어학 어플리케이션 마이풀',
    videoUrl: 'https://player.vimeo.com/video/980534965?autoplay=1',
  },
  {
    src: '/assets/img/cf/ample.jpg',
    title: 'AMPLE:N',
    videoUrl: 'https://player.vimeo.com/video/979672285?autoplay=1',
  },
];
