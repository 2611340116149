import React, { useState, useEffect } from 'react';
import Header from '../components/common/Header';
import { Link } from 'react-router-dom';
import { SectionsContainer, Section } from 'react-fullpage';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let options = {
    activeClass: 'active', // the class that is appended to the sections links
    anchors: ['a', 'b', 'c'], // the anchors for each sections
    arrowNavigation: true, // use arrow keys
    className: 'SectionContainer', // the class name for the section container
    delay: 1000, // the scroll animation speed
    navigation: true, // use dots navigation
    scrollBar: false, // use the browser default scrollbar
    sectionClassName: 'Section', // the section class name
    sectionPaddingTop: '0', // the section top padding
    sectionPaddingBottom: '0', // the section bottom padding
    verticalAlign: false, // align the content of each section vertical
  };

  return (
    <div className="Home">
      <Helmet>
        <title>화가;악studio</title>
      </Helmet>
      <Header />
      {isMobile ? (
        <main className="mobile-main bg-gray-100 h-screen flex flex-col items-center justify-between p-8">
          <div className="flex-grow flex items-center justify-center fade-in">
            <Link to="/works">
              <img src="/assets/img/logo1.png" alt="로고 아이콘" />
            </Link>
          </div>
          <div className="pb-8">
            <p className="text-[#0B3F61] text-sm font-light">
              COPYRIGHT © 2024 - HWAGA;AK
            </p>
          </div>
        </main>
      ) : (
        <SectionsContainer {...options}>
          <Section>
            <video
              className="video-element"
              src="/assets/video/peach.mp4"
              muted
              loop
              autoPlay
              playsInline
            ></video>
          </Section>
          <Section>
            <video
              className="video-element"
              src="/assets/video/fire.mp4"
              muted
              loop
              autoPlay
              playsInline
            ></video>
          </Section>
          <Section>
            <video
              className="video-element"
              src="/assets/video/media.mp4"
              muted
              loop
              autoPlay
              playsInline
            ></video>
          </Section>
        </SectionsContainer>
      )}
    </div>
  );
};

export default Home;
