import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Footer from '../components/common/Footer';
import { worksItems } from '../data/worksItems';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Link, useLocation } from 'react-router-dom';
import { cfItems } from '../data/cfItems';
import SubMenu from '../components/common/SubMenu';
import Modal from '../components/common/Modal';
import Items from '../components/common/Items';
import { Helmet } from 'react-helmet-async';

function Work() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation();

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cfItems.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + cfItems.length) % cfItems.length
    );
  };

  const currentVideoUrl = cfItems[currentIndex].videoUrl;
  const currentTitle = cfItems[currentIndex].title;

  return (
    <>
      <Helmet>
        <title>CF | HWAGA;AK</title>
      </Helmet>
      <div className="mx-auto bg-gray-100">
        <div className="md:pt-24 pb-16 flex-row justify-center gap-28 text-lg md:flex">
          <SubMenu url={'/works/cf'} menu={'CF'} />
          <SubMenu url={'/works/mv'} menu={'MV'} />
          <SubMenu url={'/works/contents'} menu={'CONTENTS'} />
        </div>
        <Items items={cfItems} openModal={openModal} />
      </div>

      <Modal
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        currentTitle={currentTitle}
        currentVideoUrl={currentVideoUrl}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
    </>
  );
}

export default Work;
