import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSecondVisible, setIsSecondVisible] = useState(false);
  const secondSectionRef = useRef(null);

  useEffect(() => {
    setIsVisible(true);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsSecondVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    if (secondSectionRef.current) {
      observer.observe(secondSectionRef.current);
    }

    return () => {
      if (secondSectionRef.current) {
        observer.unobserve(secondSectionRef.current);
      }
    };
  }, []);

  return (
    <div className="bg-[#0B3F61]  mt-4">
      <Helmet>
        <title>ABOUT | HWAGA;AK</title>
      </Helmet>
      <main className="w-full">
        <div
          className={`min-h-screen flex flex-col justify-center items-start xs:ml-2 md:ml-16 lg:ml-52  transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
        >
          <div className="flex flex-col items-start">
            <p
              className={`text-white mt-6 p-4 text-5xl md:text-6xl font-semibold mb-12 md:mb-8 transition-all duration-1000 ${isVisible ? 'animate-slide-in-left' : ''}`}
            >
              FROM OUR ANGLE OF VIEW
            </p>
            <p
              className={`text-white mt-2 p-4 text-5xl md:text-6xl font-semibold mb-4 transition-all duration-1000 ${isVisible ? 'animate-slide-in-left' : ''}`}
            >
              FOR YOUR NEEDS
            </p>
          </div>
        </div>
        <div
          ref={secondSectionRef}
          className={`pb-40 flex flex-col justify-center items-start t xs:ml-2 md:ml-16 lg:ml-52 transition-opacity duration-1000 ${isSecondVisible ? 'opacity-100' : 'opacity-0'}`}
        >
          <div className="flex flex-col items-center md:items-start text-left">
            <p
              className={`font-PretendardRegular text-white text-left text-base xs: p-4 md:text-lg mb-12 leading-relaxed transition-all duration-1000 ${isSecondVisible ? 'animate-fade-in-down' : ''}`}
            >
              화가;악 studio는 대한민국의 종합 프로덕션입니다. <br /> 우리는
              다양한 미디어 산업 분야의 영상을 다각적으로 바라봅니다. <br />{' '}
              적극적인 소통을 통해 아이디어를 제시하고, <br />
              클라이언트가 원하는 영상을 만들어갈 수 있도록 프로젝트를
              수행합니다.
            </p>
            <p
              className={`font-PretendardRegular text-white text-base xs: p-4 md:text-md font-light mb-12 mt-4 leading-relaxed transition-all duration-1000 ${isSecondVisible ? 'animate-fade-in-down' : ''}`}
            >
              HWAGA;AK STUDIO IS A COMPREHENSIVE <br /> PRODUCTION IN SOUTH
              KOREA. <br /> WE APPROACH VIDEOS FROM VARIOUS MEDIA INDUSTRIES{' '}
              <br /> FROM MULTIPLE PERSPECTIVES. <br /> THROUGH ACTIVE
              COMMUNICATION, <br /> WE PROPOSE IDEAS AND EXECUTE PROJECTS TO
              <br /> CREATE THE VIDEOS OUR CLIENTS WANT.{' '}
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default About;
