import { Link, useLocation } from 'react-router-dom';
import React from 'react';

function SubMenu({ url, menu }) {
  const location = useLocation();

  return (
    <Link to={url} className="md:flex flex-row items-center gap-1 sm: hidden">
      <img
        src={'/assets/img/logoIcon.png'}
        alt={'로고 아이콘'}
        className={location.pathname === `${url}` ? 'max-w-5 h-auto' : 'hidden'}
      />
      <span
        className={
          location.pathname === `${url}`
            ? 'text-[#0B3F61] font-bold'
            : 'text-slate-800 font-normal'
        }
      >
        {menu}
      </span>
    </Link>
  );
}

export default SubMenu;
