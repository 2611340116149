import React from 'react';

function Items({ items, openModal }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-0">
      {items.map((image, index) => (
        <div
          key={index}
          className="relative group overflow-hidden aspect-w-16 aspect-h-9 xs: mb-1 sm:mb-0"
          onClick={() => openModal(index)}
        >
          <img
            src={image.src}
            alt={image.title}
            className="w-full h-full object-cover  transition-transform duration-500 ease-in-out transform sm: group-hover:scale-105 md:group-hover:scale-105 md:group-hover:brightness-50"
          />
          <div className="absolute inset-0 flex items-end justify-start bg-gray-900 bg-opacity-0 group-hover:bg-opacity-50 transition-opacity duration-500 ease-in-out cursor-pointer">
            <span className="text-white text-lg font-bold px-6 py-4 opacity-100 md:opacity-0 md:group-hover:opacity-100 duration-300">
              {image.title}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Items;
